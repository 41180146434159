import { Box, Grid, Input, Label, Text } from "@theme-ui/components";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { useMailChimpForm } from "use-mailchimp-form";
import checkEmailValid from "../../lib/check-email-valid";
import AppButton from "../layout/app-button";
import ColumnContainer from "../layout/column-container";
import eventTrack from "../../lib/event-track";

const U = "519bd13681fb054ebbc77e599";

const ID = "294c967963";

const URL_BASE = `https://aqi.us5.list-manage.com/subscribe/post-json`;

const checkInputValid = (i) => i.length > 1 && /^[a-zA-Z]+$/.test(i);

const Form = () => {
  const endpoint = `${URL_BASE}?u=${U}&id=${ID}`;
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [lastNameValid, setLastNameValid] = useState(false);
  const { loading, error, success, message, handleSubmit } =
    useMailChimpForm(endpoint);
  useEffect(() => {
    setEmailValid(checkEmailValid(email));
    setFirstNameValid(checkInputValid(firstName));
    setLastNameValid(checkInputValid(lastName));
  }, [
    email,
    firstName,
    lastName,
    setFirstNameValid,
    setLastNameValid,
    setEmailValid,
  ]);
  useEffect(() => {
    if (success || error) {
      setEmail("");
      setFirstName("");
      setLastName("");
    }
  }, [success, error, setEmail, setFirstName, setLastName]);
  return (
    <Grid
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        if (emailValid && firstNameValid && lastNameValid) {
          handleSubmit({
            MERGE0: email,
            MERGE1: firstName,
            MERGE2: lastName,
          });
          eventTrack("Newsletter Signup Succeeded", {
            props: {
              url: window.location.pathname,
            },
          });
        }
      }}
      sx={{
        "& input": {
          color: "white",
          borderBottomColor: "white",
          "&:placeholder": {},
        },
        label: {
          color: "yellow",
          fontSize: [2],
        },
      }}
    >
      {success && (
        <Text
          as="p"
          sx={{
            color: "white",
            "& a:hover": { color: "white", textDecoration: "underline" },
          }}
          dangerouslySetInnerHTML={{ __html: "Done! " + message }}
        />
      )}
      {error && (
        <Text
          sx={{
            color: "white",
            "& a:hover": { color: "white", textDecoration: "underline" },
          }}
          dangerouslySetInnerHTML={{ __html: message + "." }}
        />
      )}
      <Box>
        <Label name="First Name" htmlFor={"firstName"}>
          First Name{firstNameValid && <span sx={{ pl: 2 }}> ✓</span>}
        </Label>
        <Input
          name="firstName"
          placeholder="Sam"
          disabled={loading}
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Box>
      <Box>
        <Label name="Last Name" htmlFor={"lastName"}>
          Last Name{lastNameValid && <span sx={{ pl: 2 }}> ✓</span>}
        </Label>
        <Input
          name="lastName"
          value={lastName}
          disabled={loading}
          placeholder="Bloggs"
          type="text"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Box>
      <Box>
        <Label htmlFor="email">
          Email{emailValid && <span sx={{ pl: 2 }}> ✓</span>}
        </Label>
        <Input
          type="email"
          disabled={loading}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="sam.bloggs@example.com"
        />
      </Box>
      <AppButton
        colors={{
          default: {
            color: "text",
            background: "yellow",
            iconColor: "text",
          },
        }}
        icon="arrowRight"
        type="submit"
        disabled={loading}
        sx={{
          mt: [4],
          borderRadius: 20,
        }}
      >
        {loading ? "Sending" : "Sign Up"}
      </AppButton>
    </Grid>
  );
};

const NewsletterSignup = () => {
  const data = useStaticQuery(graphql`
    query NSIQuery {
      image: file(relativePath: { eq: "newsletter-splash.jpg" }) {
        c: childImageSharp {
          f: fluid(maxWidth: 600, quality: 60) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <ColumnContainer>
      <Grid id="newsletter" columns={[1, 1, 3, 2]} gap={0}>
        <Box
          sx={{
            backgroundImage: `url(${data.image.c.f.src})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            borderRadius: ["30px 30px 0 0", null, "30px 0 0 30px"],
            height: "100%",
            minHeight: [300, 450, "initial"],
          }}
          src={data.image.c.f.src}
        />
        <Box
          sx={{
            bg: "highlight",
            borderRadius: ["0 0px 30px 30px", null, "0 30px 30px 0"],
            gridColumn: ["span 1", "span 2", null, "span 1"],
          }}
        >
          <Grid sx={{ p: [4, 5] }} columns={1} gap={[4]}>
            <Text
              sx={{
                color: "white",
                fontSize: [4, 5, 6],
                lineHeight: 1.4,
              }}
              as="h2"
            >
              Sign up to our newsletter
            </Text>
            <Text as="p" sx={{ color: "white", lineHeight: 1.7 }}>
              Sign up for news and alerts
            </Text>
            <Form />
          </Grid>
        </Box>
      </Grid>
    </ColumnContainer>
  );
};

export default NewsletterSignup;
